<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="WFO Permission">
      <template #page-header>
        <h2 class="page-title">WFO Permission</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <div class="columns">
          <div class="column is-9">
            <div class="level">
              <div class="level-left">
                <b-button
                  tag="button"
                  class="is-command"
                  icon-left="plus"
                  @click.native="openModalCreate()"
                >
                  New
                </b-button>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <b-field class="search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-right="magnify"
                icon-right-clickable
                @icon-right-click="searchIconClick"
                :loading="isWFOPermissionLoading"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
        >
          <b-table
            :data="wfoPermissionRequestData"
            :per-page="perPage"
            :loading="isWFOPermissionLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            class="employment-directory-table general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="id"
                label="Request ID"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ props.row.id }}
                </span>
              </b-table-column>

              <b-table-column
                field="wfo_date"
                label="Date"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="wfo_shift"
                label="Session"
                v-slot="props"
                sortable
                cell-class="table-in-office-session"
              >
                <span :class="formatSessionClass(props.row.shift)">
                  {{ formatSession(props.row.shift) }}
                </span>
              </b-table-column>

              <b-table-column
                field="note"
                label="Purpose"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ props.row.reason }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <!-- Edit icon for next sprint. Don't delete!!! -->
                <!-- <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalCreate(wfoPermissionRequestData[props.index])
                  "
                ></b-icon> -->
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <div class="has-text-centered" v-if="isLoadMore">Please Wait ...</div>

        <!-- Modal to create new request -->
        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are You sure?"
          v-if="isModalCreateOpen"
          @hide="closeModalCreate"
          :is-modal="true"
          class="modal-wfo-permission"
        >
          <template #page-content>
            <h3 class="modal-title has-text-weight-black">
              Create WFO Permission
              <span class="office-pointer-click is-pulled-right">
                <b-icon
                  icon="close"
                  size="is-small"
                  @click.native="closeModalCreate()"
                ></b-icon>
              </span>
            </h3>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(submit)"
                class="is-label-14-px-normal form-timeoff-type"
                multipart
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <ValidationProvider
                      name="Date"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Date"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-datepicker
                          v-model="data.date"
                          placeholder="Pick Date"
                          icon-right="calendar"
                          :locale="locale"
                          :min-date="minDate"
                          editable
                          class="in-office-datepicker"
                          :disabled="isEditing && data.status !== 'waiting'"
                        ></b-datepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-12 section-radio-tooltip">
                    <ValidationProvider
                      name="Session"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-tooltip
                        position="is-right"
                        class="tooltip-date"
                        multilined
                      >
                        <b-icon
                          icon="help-circle"
                          size="is-small"
                          class="tooltip-date-icon"
                        ></b-icon>
                        <template v-slot:content>
                          <div class="is-flex">
                            <div class="time-detail">Morning</div>
                            <div>: 08.00 - 12.00</div>
                          </div>
                          <div class="is-flex">
                            <div class="time-detail">Afternoon</div>
                            <div>: 13.00 - 18.00</div>
                          </div>
                          <div class="is-flex">
                            <div class="time-detail">Fullday</div>
                            <div>: 08.00 - 18.00</div>
                          </div>
                        </template>
                      </b-tooltip>
                      <b-field
                        label="Session"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                        class="radio-btn-in-office"
                      >
                        <b-radio
                          v-model="data.shift"
                          native-value="1"
                          :disabled="isEditing && data.status !== 'waiting'"
                        >
                          Morning
                        </b-radio>
                        <b-radio
                          v-model="data.shift"
                          native-value="2"
                          :disabled="isEditing && data.status !== 'waiting'"
                        >
                          Afternoon
                        </b-radio>
                        <b-radio
                          v-model="data.shift"
                          native-value="3"
                          :disabled="isEditing && data.status !== 'waiting'"
                        >
                          Fullday
                        </b-radio>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-12">
                    <ValidationProvider
                      name="Purpose"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Purpose"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-input
                          v-model="data.reason"
                          placeholder="Write down the purpose"
                          type="textarea"
                          :disabled="isEditing && data.status !== 'waiting'"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column">
                    <b-button
                      type="is-primary"
                      native-type="submit"
                      expanded
                      :loading="isSubmitting"
                      class="modal-submit-btn"
                      :disabled="isEditing && data.status !== 'waiting'"
                    >
                      Submit
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>

        <b-sidebar
          v-if="isRightBarOpen && data != null"
          can-cancel
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                WFO Permission Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>
            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedRequest.date) }}
              </div>
              <div class="column is-4">Session</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatSession(selectedRequest.shift) }}
              </div>
              <div class="column is-4">Purpose</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedRequest.reason }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalize">
                {{ selectedRequest.status }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedRequest.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="selectedRequest.status === 'waiting'"
              >
                <b-button
                  expanded
                  @click="updateStatusToCancel(selectedRequest.id)"
                >
                  Cancel
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast, determineFontColorByStatus } from '@/services/util'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'

export default {
  components: { TimeOffApprovalHistoryComponent },
  computed: {
    ...mapGetters({
      wfoPermissionRequestData: 'wfoPermission/getWFOPermissionRequestData',
      currentUser: 'auth/currentUser',
    }),
  },
  data() {
    return {
      stickyHeaders: true,

      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'desc',
      sortField: 'updated_at',
      sortOrder: 'desc',
      search: '',

      showDetailIcon: true,

      isWFOPermissionLoading: false,
      isLoadMore: false,

      isModalCreateOpen: false,
      data: {
        id: null,
        date: null,
        shift: null,
        reason: null,
        status: null,
      },
      locale: 'en-GB',
      minDate: new Date(),
      isSubmitting: null,

      selectedRequest: null,
      isModalDetailOpen: false,
      isUpdatingStatus: false,
      isEditing: false,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,
    }
  },
  methods: {
    ...mapActions({
      actionLoadWFOPermissionRequestData:
        'wfoPermission/fetchWFOPermissionRequestData',
      saveWFOPermission: 'wfoPermission/saveWFOPermission',
      cancelWFOPermissionRequest: 'wfoPermission/cancelWFOPermissionRequest',
      updateWFOPermissionRequest: 'wfoPermission/updateWFOPermissionApproval',
      currentUser: 'auth/currentUser',
    }),

    ...mapMutations({
      setWFOPermissionRequestsData: 'wfoPermission/setWFOPermissionRequestData',
    }),

    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    formatDate(str) {
      if (str) {
        return moment(str).format('DD/MM/YYYY')
      }
    },

    formatSession(data) {
      if (data === 1) {
        return 'Morning'
      } else if (data === 2) {
        return 'Afternoon'
      } else if (data === 3) {
        return 'Full Day'
      }
    },

    formatSessionClass(data) {
      if (data === 1) {
        return 'session-morning'
      } else if (data === 2) {
        return 'session-afternoon'
      } else if (data === 3) {
        return 'session-full-day'
      }
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadWFOPermission()
        this.isLoadMore = false
      }
    },

    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setWFOPermissionRequestsData([])
      await this.loadWFOPermission()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadWFOPermission()
    },

    async loadWFOPermission() {
      this.isWFOPermissionLoading = true

      try {
        const response = await this.actionLoadWFOPermissionRequestData({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isWFOPermissionLoading = false
    },

    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.data = {
          id: row.id,
          date: new Date(row.date),
          shift: row.shift,
          reason: row.reason,
          status: row.status,
        }
      }
      this.isModalCreateOpen = true
    },

    closeModalCreate() {
      this.resetForm()
      this.isModalCreateOpen = false
      this.isEditing = false
    },

    openSidebar(wfoPermission) {
      this.selectedRequest = wfoPermission
      this.isRightBarOpen = true
    },

    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    closeSidebar() {
      this.isRightBarOpen = false
    },

    async submit() {
      this.isSubmitting = true
      let form = new FormData()

      try {
        form.append('date', moment(this.data.date).format('YYYY-MM-DD'))
        form.append('shift', this.data.shift)
        form.append('reason', this.data.reason)

        await this.saveWFOPermission(form)
        showToast('Data is successfully added!', 'is-success', 'is-bottom')

        this.resetForm()
        this.isModalCreateOpen = false
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isSubmitting = false
    },

    resetForm() {
      this.data = {
        date: null,
        shift: null,
        reason: null,
      }
    },

    openModalDetail() {
      this.isModalDetailOpen = true
    },

    closeModalDetail() {
      this.isModalDetailOpen = false
    },

    async updateStatusToCancel(id) {
      this.isUpdatingStatus = true
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        try {
          await this.cancelWFOPermissionRequest(id)
          showToast(
            'Request is successfully canceled!',
            'is-success',
            'is-bottom'
          )
        } catch (err) {
          if (err.response.data.message) {
            showToast(err.response.data.message, 'is-danger', 'is-bottom')
          }
          console.log(err)
        }
      }

      this.isUpdatingStatus = false
      this.closeSidebar()
    },
  },

  async mounted() {
    this.setWFOPermissionRequestsData([])
    await this.loadWFOPermission()
  },
}
</script>
